.heading-title > span {
    color: #1c87d3;
    font-family: Raleway;
    font-weight: 400;
    text-transform: unset;
}

.heading-title h2 {
    color: #304059;
    font-size: 55px;
    font-family: Raleway;
}

.whitecolor {
    color: #ffffff!important;
}

.darkcolor {
    color: #34445e;
}

.btnprimary, .btnprimary-alt {
    background: #1c87d3;
    border: 1px solid #1c87d3;
}

.btnprimary:hover, .btnprimary:focus {
    background: #304059;
    border: 1px solid #304059;
}

.back-top {
    background: unset;
    background-color: #304059;
}

.back-top::before {
    background: unset;
    background-color: #1c87d3;
}

@media (max-width: 575px) {

    .heading-title > span {
        font-size: 1.8rem;
    }

    .heading-title h2 {
        font-size: 35px;
    }
}

.tp-parallax-wrap >h1{
    left: 72px!important;

}