.fa-desktop-and-mobile:before {
    content : url(./desktop-tablet-mobile.png);
}
.fa-mobile:before {
    font-size: 45px;
}
.fa-desktop:before {
    font-size: 30px;
}

.portfolio-icon {
    display: flex;
    padding: 12px;
}

.portfolio-project-title {
    cursor: pointer;
    transform: scale(1);
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out !important;
}

.portfolio-project-title:hover {
    font-weight: 600;
    transform: scale(1.05);
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out !important;
}
.projectName h4 {
    font-weight: 600;
}
.projectName {
    text-align: right;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
}
#portfolio-xe .cbp-l-filters .cbp-filter-item {
    margin: .2rem;
}
.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:focus span {
    border: 1px solid #1b87d0;
    background: #1b87d0;
}

.cbp-l-filters .cbp-filter-item:hover span {
    border: 1px solid #1d6fbf;
    background: #1d6fbf;
}

#portfolio-xe .overlay {
    align-items: flex-end;
    background: #1b87d0e6;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    left: 0;
    right: 0;
    top: 0;
}

.cbp-l-filters .cbp-filter-item span.no-transform {
    text-transform: none;
}

@media (max-width: 992px) {

    .cbp-l-filters .cbp-filter-item:hover span {
        background: unset;
        border: 1px solid #b7b7b7;
        color: #b7b7b7;
    }

    .cbp-l-filters .cbp-filter-item.cbp-filter-item-active span {
        border: 1px solid #1b87d0;
        background: #1b87d0;
        color: #fff;
    }
}
