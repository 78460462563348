#solutions {
    padding: 7.5rem 0 0rem 0;
}

.solution-title {
    margin: 5px!important;
}

.solution-content {
    padding: 40px 0;
}

.solution-content .solution-center-left {
    left: 5%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.solution-content .solution-center-left img {
    transform: scale(.8);
}

.solution-content .solution-center-right {
    display: flex;
    align-self: center;
    padding: 0 5% 0 10%;
}

#solutions .slick-track {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}

#solutions .slick-dots {
    top: 30px;
    align-content: flex-start;
    display: flex!important;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 15%;
    height: 80px;
}

#solutions .slick-dots li {
    height: 35px;
    margin: .2rem .2rem;
    width: unset;
}
#solutions .slick-dots .slick-active > a p {
    border: 1px solid #1b87d0;
    color: #fff;
    background: #1b87d0;
}

#solutions .slick-dots .slick-active:hover > a p {
    border: 1px solid #1d6fbf;
    background: #1d6fbf;
}

#solutions .slick-dots li a:hover > p {
    border: 1px solid #1b87d0;
    color: #fff;
    background: #1b87d0;
}

#solutions .slick-dots li a p {
    color: #a1a9b6;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    position: relative;
    padding: 5px 1.125rem;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #a1a9b666;
}

#solutions .solution-content p b {
    color: #1d6fbf;
    font-weight: 500;
}

#solutions .solution-content ul {

    list-style-type: disc;
    line-height: 1.5rem;
    text-align: left;
    padding-left: 5%;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
}

#solutions .solution-content ul b {
    color: unset;
    font-weight: 600;
}

#solutions .slick-next, #solutions .slick-prev {
    z-index: 30;
    cursor: pointer;
    padding: 5% 2%;
    top: 100px;
}
#solutions .slick-next:before, #solutions .slick-prev:before {
    bottom: 20px;
    color: #1b87d0;
    font-size: 50px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    padding-right: 0.5em;
    position: absolute;
    text-decoration: inherit;
}

#solutions .slick-prev:before {
    content:'\f104';
    right: -10px;
}

#solutions .slick-next:before {
    content:'\f105';
    left: 10px;
}


@media (max-width: 992px) {

    #solutions .slick-dots .slick-active:hover > a p {
        border: 1px solid #1b87d0;
        color: #fff;
        background: #1b87d0;
    }

    #solutions .slick-dots li a:hover > p {
        border: 1px solid #a1a9b666;
        color: #a1a9b6;
        background: unset;
    }

    #solutions .slick-next {
        right: 0px;
        top: 18%;
    }

    #solutions .slick-prev {
        left: 0px;
        top: 18%;
    }

    #solutions .solution-content {
        padding: 17% 0 60px;
    }

}

@media (max-width: 575px) {
    .heading-title.slider {
        padding-bottom: 250px;
    }
    #solutions .slick-next, #solutions .slick-prev {
        padding: 13% 6%;
            top: 30px;

    }

    #solutions .solution-content {
        padding: 0 0 60px;
    }

    #solutions .slick-dots {
        top: -250px;
        position: absolute;
        padding: 8%;
    }

    #solutions .solution-content .solution-center-left {
        left: 0%;
        margin-top: 0;
        margin-bottom: 0;
        height: unset;
        display: flex;
        justify-content: center;
        align-content: center
    }
    #solutions .solution-content .solution-center-left .image  {
        text-align: center!important;
    }

    #solutions .solution-content .solution-center-left .image img {
        display: flex!important;
        justify-content: center!important;
        width: 100%;
    }

    #solutions .solution-content .solution-center-left img {
        height: 100%;
        width: auto;
    }

    #solutions .solution-content .solution-center-right {
        margin-top: -7%;
        padding: 0 10% 0 10%;
    }
}