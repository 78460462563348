.menu-color-style .navbar-nav .nav-link {
    font-weight: 600;
}
.menu-color-style .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus, .sidebar-nav .navbar-nav .nav-link.active {
    color: #1b87d0;
}

.side-menu-color-style {
    background-color: #34445e!important;
}
.sidemenu_btn {
    cursor: pointer;
}