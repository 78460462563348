#our-team .our-team-background-image {
    position: absolute;
    top: 25%;
    width: 100%;
}

#our-team .image img {
    filter: grayscale(0%) brightness(1);
    -webkit-filter: grayscale(0%) brightness(1);
}

#our-team .image img:hover {
    filter: grayscale(0%) brightness(1.1);
    -webkit-filter: grayscale(0%) brightness(1.1); /* Safari */
}

#our-team .heading-title > span {
    color: #1c87d3;
    font-family: Raleway;
    font-weight: 400;
}

#our-team .heading-title h2 {
    color: #304059;
    font-family: Raleway;
}

#our-team .team-content {
    cursor: default;
    color: #ffff;
    height: 150px;
}

#our-team .team-box .grey-shade {
    background: #ffffff00;
}

#our-team .team-content h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
}

#our-team .team-content p {
    color: #a1a9b6;
    font-size: 15px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;

}
#our-team .team-content i {
    background-color: #34445e;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    padding: 7px;
    padding-left: 9px;
    height: 30px;
    width: 30px;
}
#our-team .team-content i:hover {
    background-color: #fff;
    color: #34445e;
}
#our-team .team-box.no-hover:hover .team-content p {
    color: #fff;
}
#our-team .telego-team .telego-box:nth-child(1) .team-content::before {
    background: #1b87d0;
}

#our-team .telego-team .telego-box:nth-child(2) .team-content::before {
    background: #68d1f1;
}

#our-team .telego-team .telego-box:nth-child(3) .team-content::before {
    background: #34445e;
}

#our-team .telego-team .telego-box:nth-child(4) .team-content::before {
    background: #3dced1;
}

#our-team .team-box.grey-shade {
    background-color: transparent;
}

@media (max-width: 1200px) {
    #our-team .team-content h3 {
        font-size: 17px;

    }
}
@media (max-width: 992px) {

    #our-team .our-team-background-image {
        display: none;
    }
    #our-team .team-content {
        padding: 15px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        align-items: center;
    }
    #our-team .team-content h3 {
        font-size: 20px;
        font-weight: 600;
    }

    #our-team .team-content p {
        font-size: 13px;
        font-weight: 300;
    }
    #our-team .team-content i {
        padding: 8px;
    }
}

@media (max-width: 575px) {

    #our-team .our-team-background-image {
        display: none;
    }

    #our-team .telego-box {
        padding: 0 20%;
    }

    #our-team .team-content {
        padding: 15px;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    #our-team .team-content h3 {
        font-size: 15px;
        font-weight: 600;
    }

    #our-team .team-content p {
        font-size: 15px;
        font-weight: 300;
    }
    #our-team .team-content i {
        padding: 10px;
    }
}