#technology #services-slider {
    display: flex;
    margin: 0;
    min-height: unset;
}

#technology #services-slider .item, #technology #services-slider .item-center {
    align-self: baseline;
}

#technology #services-slider .item .service-box {
    padding: 3rem 1rem;
    background: #1b87d0;
}

#technology #services-slider .item-center .service-box {
    height: 100%;
    background: #34445e;
    padding: 4.5rem 1rem;
    margin-top: -25px;
}

#technology #services-slider .item-center .service-box::before {
    background: unset;
    background-color: #21314b;;
}

#technology #services-slider .service-box::before {
    background: unset;
    background-color: #1c6eb9;
}

.technology-card-wrapper {
    display: flex;
}

.technology-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15% 15% 10%;
}

.technology-card:hover {
    background: #32435D;
    border-color: #32435D;
}

.technology-h3 {
    color: #1C87D3;
    width: 100%;
}

.technology-tags {
    display: flex;
    flex-wrap: wrap;
}

.technology-tag {
    color: #ffffff;
    cursor: pointer;
    box-sizing: padding-box;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    position: relative;
    margin: 0;
    top: 30px;
    transition: all .3s ease;
}

.technology-tag:hover {
    transform: scale(1.1);
    font-weight: 500;
}

#technology #services-slider .service-box > span {
    align-items: center;
    font-size: 50px;
    justify-content: center;
    height: 65px;
    width: 100%;
}

#technology #services-slider .service-box > span {
    transform: scale(1);
    transition: all 1s ease-out !important;
}

#technology #services-slider .service-box:hover > span  {
    transform: scale(1.2);
    transition: all 1s ease-out !important;
}
#technology #services-slider .service-box:hover > span:first-child {
    transform: scale(1.1);
}

@media (max-width: 992px) {
    #technology #services-slider {
        flex-wrap: wrap;
    }

    #technology #services-slider .item-center .service-box {
        margin-top: 0px;
        padding: 3rem;
    }

    #technology #services-slider .item .service-box {
        padding: 3rem;
    }

    #technology #services-slider .item, #technology #services-slider .item-center {
        margin: 2% 0;
    }

    .technology-tags {
        padding-top: 2%;
    }

    .technology-tag {
        position: unset;
        top: unset;
    }
}