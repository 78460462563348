#our-solutions .high-quality-image {
    top: 26px;
    left: 40px;
}

@media (max-width: 992px) {

    #our-solutions .high-quality-right {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    #our-solutions .high-quality-image {
        top: unset;
        left: unset;
    }
}

@media (max-width: 575px) {
    #our-solutions .high-quality-image {
        margin-top: 10px;
    }
}