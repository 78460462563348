#contact .our-address .pickus {
    color: #1c87d3;
}

.footer-msg-response {
    width: 100%;
}
.footer-msg-response.ok{
    background: #2b94d630;
    border-radius: 5px;
    color: #2b94d6;
    font-weight: 600;
    padding: 5px 20px;
}
.footer-msg-response.error {
    background: #ca7e7e29;
    border-radius: 5px;
    color: #882121;
    font-weight: 400;
    padding: 5px 20px;
}
