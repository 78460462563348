.our-work-logo {
    filter: grayscale(100%) opacity(50%);
    -webkit-filter: grayscale(100%) opacity(50%); /* Safari */
}

.our-work-logo:hover {
    filter: grayscale(0%) opacity(100%);
    -webkit-filter: grayscale(0%) opacity(100%); /* Safari */


}