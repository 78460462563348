#project-info.single-feature {
    padding-top: 7%;
}
.page-header {
    height: 300px;
}

#hero-aboutUs {
    background-size: cover!important;
}
@media (max-width: 1400px) {
    .page-header {
        height: 300px;
    }
}

@media (max-width: 1200px) {

    #project-info.single-feature {
        padding-top: 10%;
    }
    .page-header {
        height: 220px;
        display: none;
    }
}

@media (max-width: 992px) {
    .page-header {
        height: 300px;
    }
}

@media (max-width: 768px) {

    #project-info.single-feature {
        padding-top: 17%;
    }

    .page-header {
        height: 170px;
    }
}

@media (max-width: 575px) {

    #project-info {
        padding: 60px 0;
    }

    .project-info-left {
        padding: 10% 5%;
    }

    .project-info-right img {
        width: 70%;
    }
    #project-info .heading-title span {
        padding-bottom: 5px;
    }
}