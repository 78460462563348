.hover-hero-text-style {
    transform: scale(1);
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out !important;
    font-size: 45px!important;
    line-height: 60px!important;
    margin: 0px!important;
}
.hover-hero-text-style:hover {
    color: #1b87d0;
    transform: scale(1.02);
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out !important;

}
@media (max-width: 992px) {
    .hero-small-text {
        line-height: 25px!important;
        font-size: 14px!important;
        margin-top: 14px!important;

    }
}

@media (max-width: 575px) {

    .image-show img {
        display: none!important;
    }

    .hero-small-text {
        font-size: 20px!important;
        line-height: 25px!important;
    }
}


.tp-caption.tp-resizeme {
    display: block;
}

.tp-caption.tp-resizeme > h1 {
    margin: 0 auto 0 auto;
}