#work-process {
    background: unset;
    background-color: #34445e;
}

#work-process .process-wrapp li::before, .process-wrapp li::after {
    content: unset;
}
/*#work-process .process-wrapp li {*/
    /*width: 20%;*/
/*}*/

#work-process .process-wrapp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#work-process .process-wrapp li {
    align-items: center;
    cursor: auto;
    display: flex;
    flex-direction: column;
    width: 30%;
}

#work-process .process-wrapp li > .pro-step {
    background-size: 35% 35%;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}

#work-process .process-wrapp li:hover > .pro-step {
    -webkit-box-shadow: unset;
    box-shadow: unset;
    background-color: #ffffff;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}

#work-process .process-wrapp li .planning {
    background-image: url("icon_work_process/planning.png");
}

#work-process .process-wrapp li:hover .planning {
    background-image: url("icon_blue_work_process/planning-blue.png");
}

#work-process .process-wrapp li .analyze {
    background-image: url("icon_work_process/analyze.png");
}

#work-process .process-wrapp li:hover .analyze {
    background-image: url("icon_blue_work_process/analyze-blue.png");
}

#work-process .process-wrapp li .design {
     background-image: url("icon_work_process/design.png");
 }

#work-process .process-wrapp li:hover .design {
    background-image: url("icon_blue_work_process/design-blue.png");
}

#work-process .process-wrapp li .development {
    background-image: url("icon_work_process/development.png");
}

#work-process .process-wrapp li:hover .development {
    background-image: url("icon_blue_work_process/development-blue.png");
}

#work-process .process-wrapp li .accept {
    background-image: url("icon_work_process/accept.png");
}

#work-process .process-wrapp li:hover .accept {
    background-image: url("icon_blue_work_process/accept-blue.png");
}

#work-process .process-wrapp li .deploy {
    background-image: url("icon_work_process/deploy.png");
}

#work-process .process-wrapp li:hover .deploy {
    background-image: url("icon_blue_work_process/deploy-blue.png");
}


@media (max-width: 992px) {

    #work-process .process-wrapp {
        flex-wrap: wrap;
        justify-content: center;
    }

    #work-process .process-wrapp li {
        width: 50%;
    }

}

@media (max-width: 575px) {

    #work-process .process-wrapp li {
        width: 100%;
    }

}