.fancybox-button--play {
    display: none!important;
}
.fancybox-button--thumbs {
    display: none!important;
}
.fancybox-infobar {
    display: none!important;
}
.responsive-order {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.project-info-right {
    border-left: 3px solid #a5a5a51c;
    padding-left: 10%;
    padding-bottom: 60px;
    margin-left: 10%;
}
.project-info-left img {
    width: 60%;
}

#project-info .project-gallery .telego-box {
    padding-right: 15px;
    padding-left: 15px;
}

.telego-class {
    width: 100%;
    height: 350px;
    display: flex!important;
    align-items: center;
    align-content: center;
    justify-content: left;
}

.telego-class a {
    width: 100%;
}

#project-info .slick-slide img {
    width: 100%;
}

#project-info .gallery-padding {
    padding: 0 2.5%;
}

#project-info .slick-arrow {
    z-index: 1;
}
#project-info .slick-prev {
    left: -5px;
}

#project-info .slick-next {
    right: 15px;
}
#project-info .slick-dots li button:before {
    opacity: 1;
    font-size: 10px;
    color: #a1a9b6;

}
#project-info .slick-dots li.slick-active button:before {
    color: #34445e;
}
#project-info .slick-next:before, #project-info .slick-prev:before {
    bottom: 20px;
    color: #a1a9b6;
    font-size: 50px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    padding-right: 0.5em;
    position: absolute;
    text-decoration: inherit;
}

#project-info .slick-next:hover:before, #project-info .slick-prev:hover:before {
    color: #34445e;
}
#project-info .slick-prev:before {
    bottom: 0;
    content:'\f104';
}

#project-info .slick-next:before {
    bottom: 0;
    content:'\f105';
}
@media (max-width: 769px) {
    .project-info-left img {
        width: 100%;
    }
}
@media (max-width: 575px) {

    .responsive-order {
        display: flex;
        flex-direction: column-reverse;
    }
    .responsive-order-top {
        order: 1;
    }

    .project-info-right {
        border-bottom: 3px solid #a5a5a51c;
        border-left: none;
        padding: 10% 5%;
        margin: 0;
    }

    .project-info-left img {
        width: 40%;
    }

    #project-info .gallery-padding {
        padding: 0 6%;
    }

    .telego-class {
        height: 150px;
    }

    #project-info .slick-next:before, #project-info .slick-prev:before {
        font-size: 40px;
    }

    #project-info .slick-prev {
        left: -5px;
        padding: 5%;
    }

    #project-info .slick-next {
        right: 10px;
        padding: 5%;
    }
}